<script>
import Layout from '@/router/layouts/main';
import { api } from '@/state/services';
import { VclList } from 'vue-content-loading';

export default {
  locales: {
    pt: {
      'Invoices': 'Notas Fiscais',
      'processing': 'PROCESSANDO',
      'approved': 'APROVADO',
      'repproved': 'REPROVADO',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Invoices': 'Facturas',
      'processing': 'PROCESSANDO',
      'approved': 'APROVADO',
      'repproved': 'REPROVADO',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      table: {
        heade: [
          'Chave de Acesso', 'Data', 'Status'
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    }
  },
  methods: {
    getInvoices() {
      api
        .get('invoices')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    }
  },
  mounted() {
    this.getInvoices();
  }
}
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Invoices') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td v-for="(data,row) in td" :key="row">
                      <div v-if="row === 'status'">
                        <span v-if="data === 'processing'" class="badge badge-soft-warning font-size-12">{{ t(data) }}</span>
                        <span v-else-if="data === 'approved'" class="badge badge-soft-success font-size-12">{{ t(data) }}</span>
                        <span v-else-if="data === 'repproved'" class="badge badge-soft-danger font-size-12">{{ t(data) }}</span>
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>